<template>
  <v-container>
    <div class="d-flex flex-row flex-grow-1">
      <PageTitle
        text="Sales Pending Approval"
        class=" ft font-size-sm black--text"
      />
    </div>
    <div
      class="d-flex flex-row flex-grow-1"
      style="justify-content: space-between;"
    >
      <h4 class="ft font-weight-medium font-size-sm ml-1">
        Review and Approve all pending sales recorded at the end of day
      </h4>
      <v-autocomplete
        :items="branches"
        item-value="id"
        item-text="name"
        dense
        v-model="branchId"
        outlined
        @focus="$store.dispatch('branches/list')"
        label="Choose a branch"
        class="ft font-weight-medium font-size-sm ml-7"
        style="margin-top: -6px;"
        hide-details
      >
        <template #item="{item}">
          <span class="ft font-weight-medium font-size-sm">{{
            item.name
          }}</span>
        </template>
        <template #no-data>
          <span class="ft pa-5 font-weight-medium font-size-sm"
            >No branches available</span
          >
        </template>
      </v-autocomplete>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        :nudge-left="90"
        offset-y
        min-width="auto"
        class="ft font-weight-medium mt-n1 "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="font mt-n1  ml-2 font-weight-medium "
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            min-width="120px"
            prepend-icon="done"
          >
            <span class="ft">{{ date ? date : "Choose From Date" }}</span>
          </v-btn>
        </template>
        <v-date-picker
          :max="today | timestamp('YYYY-MM-DD')"
          class="font font-weight-medium  "
          v-model="date"
          event-color="primary"
          @input="menu = false"
        ></v-date-picker>
      </v-menu>
    </div>
    <v-row>
      <v-col class="mt-1">
        <div
          v-if="pageLoading"
          class="d-flex flex-row flex-grow-1 justify-center"
        >
          <v-progress-circular indeterminate color="primary" />
        </div>
        <v-stepper v-model="e1" class="box-shadow-light">
          <v-stepper-header class="box-shadow-light">
            <v-stepper-step
              class=" ft font-weight-medium font-size-md"
              :complete="e1 > 1"
              step="1"
            >
              Shop Sales
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              class=" ft font-weight-medium font-size-md"
              :complete="e1 > 2"
              step="2"
            >
              Lube Sales
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              class=" ft font-weight-medium font-size-md"
              step="3"
            >
              Final End Of Day Sales
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card flat class="mb-12">
                <div
                  v-if="date && branchId"
                  class="d-flex flex-row flex-grow-1"
                >
                  <PendingShopSales
                    :branchId="branchId"
                    :currency="currency"
                    ref="pendingShopSale"
                    :date="date"
                  />
                </div>
                <div
                  v-else
                  class="d-flex flex-row flex-grow-1 justify-center pt-8"
                >
                  <EmptyPage
                    :image="require(`@/assets/folder.svg`)"
                    :title="'No Pending Shop Sales'"
                    :imageWidth="'100px'"
                    :subTitle="
                      `Proceed to Lube Sales by choosing a branch and date`
                    "
                  />
                </div>
              </v-card>

              <div class="d-flex flex-row flex-grow-1 justify-end">
                <v-btn text v-if="e1 > 1" @click="e1--">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="approveShopSales"
                  :disabled="(!date && !branchId) || isLoading"
                  :loading="isLoading"
                >
                  <span class=" ft font-weight-medium font-size-sm"
                    >Continue</span
                  >
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12" flat>
                <div
                  v-if="date && branchId"
                  class="d-flex flex-row flex-grow-1"
                >
                  <PendingLubeSales
                    :branchId="branchId"
                    :currency="currency"
                    ref="pendingLubeSales"
                    :date="date"
                  />
                </div>
                <div
                  v-else
                  class="d-flex flex-row flex-grow-1 justify-center pt-8"
                >
                  <EmptyPage
                    :image="require(`@/assets/folder.svg`)"
                    :title="'No Pending Lube Sales'"
                    :imageWidth="'100px'"
                    :subTitle="'Proceed to end-of-day Sales'"
                  />
                </div>
              </v-card>
              <div class="d-flex flex-row flex-grow-1 justify-end">
                <v-btn text @click="e1--">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="approveLubeSales"
                  :disabled="(!date && !branchId) || isLoading"
                  :loading="isLoading"
                >
                  <span class=" ft font-weight-medium font-size-sm"
                    >Continue</span
                  >
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="mb-12" flat>
                <div
                  v-if="date && branchId"
                  class="d-flex flex-row flex-grow-1"
                >
                  <PendingFuelSales
                    :branchId="branchId"
                    :currency="currency"
                    ref="pendingFuelSales"
                    :date="date"
                  />
                </div>
                <div
                  v-else
                  class="d-flex flex-row flex-grow-1 justify-center pt-8"
                >
                  <EmptyPage
                    :image="require(`@/assets/folder.svg`)"
                    :title="'No Pending Fuel Sales'"
                    :imageWidth="'100px'"
                    :subTitle="'Finish up here at once'"
                  /></div
              ></v-card>
              <div class="d-flex flex-row flex-grow-1 justify-end">
                <v-btn text @click="e1--" class="mr-1">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="approveFinalSales"
                  :disabled="
                    (!date && !branchId) ||
                      isLoading ||
                      pendingFinalFuelSales.length === 0
                  "
                  :loading="isLoading"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <ConfirmationDialog
      :state="dialog('alert')"
      @close="close"
      :loading="isLoading"
      @save="confirmShopSales"
    />
    <ConfirmationDialog
      :state="dialog('details')"
      @close="close"
      type="details"
      :loading="isLoading"
      @save="confirmLubeSales"
    />
    <ConfirmationDialog
      :state="dialog('edit')"
      @close="close"
      type="edit"
      :loading="isLoading"
      @save="confirmFinalFuelSales"
    />
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { timestamp } from "../../../filters/timestamp";
import SnackBarMixins from "../../../mixins/SnackBarMixins";
import DialogMixins from "../../../mixins/DialogMixins";
export default {
  name: "PendingSales",
  components: {
    PageTitle: () => import(`../../../components/PageTitle`),
    EmptyPage: () => import(`../../../components/EmptyPage`),
    PendingShopSales: () => import(`./shop/PendingShopSales`),
    PendingLubeSales: () => import(`./shop/PendingLubeSales`),
    PendingFuelSales: () => import(`./shop/PendingFuelSales`),
    SnackBar: () => import(`../../../components/SnackBar`),
    ConfirmationDialog: () => import(`../../../components/ConfirmationDialog`)
  },
  filters: {
    timestamp
  },
  data() {
    return {
      e1: 1,
      step: 0,
      pendingItems: [2, 3],
      menu: false,
      date: null,
      today: new Date(),
      branchId: null
    };
  },
  mixins: [SnackBarMixins, DialogMixins],
  computed: {
    ...mapGetters({
      pageLoading: "getIsPageLoading",
      currency: "settings/getSystemCurrency",
      pendingShopSales: "sales/getAllPendingShopSales",
      pendingLubeSales: "sales/getAllPendingLubeSales",
      pendingFinalFuelSales: "sales/getAllPendingFuelSales",
      branches: "branches/getAllBranches",
      isLoading: "getIsLoading"
    })
  },
  watch: {
    branchId(value) {
      if (value && this.date) {
        this.$store.dispatch("prices/listAllocationAmount", {
          branchId: this.branchId
        });
        this.commons(this.date, value);
      }
    },
    date(value) {
      if (value && this.branchId) {
        this.$store.dispatch("prices/listAllocationAmount", {
          branchId: this.branchId
        });
        this.commons(value, this.branchId);
      }
    },

    isLoading(value) {
      const status = this.$store.getters["getLoadNextStatus"];
      if (!value && status === 200) {
        if (this.e1 < 3) {
          this.e1++;
        } else {
          this.e1 = 1;
          this.branchId = null;
          this.date = null;
        }
      }
    }
  },
  methods: {
    commons(date, branchId) {
      //if (this.e1 > 1) this.e1 = 1;

      this.$store.dispatch("sales/pendingShopSalesList", {
        date,
        branchId
      });
      this.$store.dispatch("sales/pendingLubeSalesList", {
        branchId,
        date
      });
      this.$store.dispatch("sales/customerCreditLubeSalesList", {
        branchId: branchId,
        date
      });
      this.$store.dispatch("customers/listOfCustomers", { branchId });

      this.$store.dispatch("sales/pendingFuelSalesList", {
        branchId: branchId,
        date
      });
      this.$store.dispatch("sales/recordedFuelSales", {
        branchId: branchId,
        date
      });
    },
    approveShopSales() {
      if (this.date && this.pendingShopSales && this.branchId) {
        if (
          !this.pendingShopSales ||
          (this.pendingShopSales.cashDeposit === 0 &&
            this.pendingShopSales.salesAmount === 0 &&
            !this.pendingShopSales.salesDate)
        ) {
          this.e1++;
        } else {
          this.open("alert");
        }
      }
    },
    approveLubeSales() {
      if (this.date && this.pendingLubeSales && this.branchId) {
        if (this.pendingLubeSales.length === 0) {
          this.e1++;
        } else {
          this.open("details");
        }
      }
    },
    approveFinalSales() {
      if (this.date && this.pendingFinalFuelSales && this.branchId) {
        if (this.pendingFinalFuelSales.length !== 0) {
          this.open("edit");
        }
      }
    },
    confirmShopSales() {
      // fire the shop sales approval from the pending shop sales component
      this.$refs.pendingShopSale.recordDailySales();
    },
    confirmLubeSales() {
      // fire the lube sales approval from the pending lube sales component
      this.$refs.pendingLubeSales.recordDailyLubeSales();
    },
    confirmFinalFuelSales() {
      // fire the fuel sales approval from the pending fuel sales component
      this.$refs.pendingFuelSales.finalizeAndApproveFuelSales();
    }
  },
  created() {
    this.$store.dispatch("settings/systemCurrency");
    this.$store.dispatch("products/list", {
      isPaginated: false,
      page: 1,
      limit: 12
    });
    this.$store.dispatch("suppliers/list", {
      isPaginated: false,
      page: 1,
      limit: 12
    });
  }
};
</script>
